import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mc/components/Button';
import FormContext from './FormContext';

function ResetButton({ onClick = () => {}, ...props }) {
  const { resetForm } = useContext(FormContext);
  const handleClick = (...args) => {
    onClick(...args);
    resetForm();
  };
  return <Button {...props} onClick={handleClick} htmlType="reset" />;
}

ResetButton.propTypes = {
  /**
   * What happens when you click reset. The form will automatically handle the resetting of values and state
   * so this is typically useful for extra actions such as navigating or analytics tracking.
   */
  onClick: PropTypes.func,
};

export default ResetButton;
