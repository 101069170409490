import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useId from '@mc/hooks/useId';
import CheckboxDescription from './CheckboxDescription';
import stylesheet from './Checkbox.less';

/**
 * A wrapper around the native browser `<input type="checkbox" />`, as well as a `<label>` and other sensible defaults.
 *
 * ## Usage
 * Checkbox can be used stand-alone:
 * ```js
 * import Checkbox from '@mc/Input/Checkbox';
 *
 * function StandaloneExample() {
 *   const [foo, setFoo] = useState('');
 *   return (
 *     <Checkbox onChange={setFoo} value={foo} label="Foo" />
 *   />
 *   );
 * }
 *```
 *
 * Or as the component of a `FormField` (which must be nested inside `<Form>`), in which the required values will automatically be supplied from the `Form` context.:
 * ```js
 * import { FormField } from '@mc/components/Form';
 * import Checkbox from '@mc/Input/Checkbox';
 *
 * function FormFieldExample() {
 *   return (
 *     <FormField component={Checkbox} />
 *   />
 *   );
 * }
 * ```
 */
function Checkbox({
  id,
  // The following prop is not consumed inside `Checkbox`, but
  // provided by a generic interface. We don't need to account
  // for PropTypes or usage, so we'll just disable linting here.
  // eslint-disable-next-line react/prop-types, no-unused-vars
  isInvalid,
  label,
  onChange,
  // This prop is also not consumed, but destructured to omit
  // it from downstream `props` usages.
  // eslint-disable-next-line react/prop-types, no-unused-vars
  secondaryLabel,
  description,
  value,
  className,
  isLabelVisible,
  ...props
}) {
  const autoId = useId();
  const _id = id || autoId;

  return (
    <label className={cx(stylesheet.container, className)}>
      <input
        {...props}
        type="checkbox"
        id={_id}
        className={stylesheet.checkbox}
        checked={value}
        onChange={(event) => onChange(event.target.checked)}
      />
      {isLabelVisible ? (
        <span
          id={`${_id}-label`}
          className={cx(stylesheet.labelText, {
            [stylesheet.withDescription]: !!description,
          })}
        >
          {label}
        </span>
      ) : (
        <span className="wink-visually-hidden">
          <label htmlFor={_id}>{label}</label>
        </span>
      )}
    </label>
  );
}

Checkbox.Description = CheckboxDescription;

Checkbox.defaultProps = {
  isLabelVisible: true,
  onChange: function() {},
  value: false,
};

Checkbox.propTypes = {
  /** CSS classname to add. Appends to the `<label>` component which is the root element. */
  className: PropTypes.string,
  /** Optional description to display below label */
  description: PropTypes.node,
  /** Passed to id attribute of the input element. Autogenerated if one is not passed as a prop. */
  id: PropTypes.string,
  /** When false, replaces the label with an invisible version. */
  isLabelVisible: PropTypes.bool,
  /** Text to use for the form label. Passed to the `InputLabel` component. */
  label: PropTypes.node,
  /** Required to enforce that this component should always be controlled. */
  onChange: PropTypes.func.isRequired,
  /** Required to enforce that this component should always be controlled. */
  value: PropTypes.bool.isRequired,
};

export default Checkbox;
